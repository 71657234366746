import { Heading } from '../../../../components/Heading/Heading.styled'
import Countdown from '../../../../components/Countdown/Countdown'
import React, { FC } from 'react'
import useGame from '../../../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const CountdownView: FC = () => {
  const { game } = useGame()
  const { t } = useTranslation()

  if (!game) return null
  return (
    <>
      <Heading marginBottom="70px">{t('game.leaderboards.title')}</Heading>
      <Countdown title={t('game.leaderboards.countDownTitle') || ''} />
    </>
  )
}
