import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { useState } from 'react'
import useInterval from '@use-it/interval'
import useGame from '../../../../contexts/GameContext/useGame'

interface TimeDifferenceResponse {
  [key: string]: number
}

const useCountdown = () => {
  const { game } = useGame()
  const to = new Date(game?.info.startsAt || '')

  const getTimeDifference = (): TimeDifferenceResponse => ({
    days: differenceInDays(to, new Date()),
    hours: differenceInHours(to, new Date()) % 24,
    minutes: differenceInMinutes(to, new Date()) % 60,
    seconds: differenceInSeconds(to, new Date()) % 60,
  })

  const [timeToNow, setTimeToNow] = useState(getTimeDifference())
  const [isZero, setIsZero] = useState(false)

  useInterval(() => {
    const timeDifference = getTimeDifference()
    const { days, hours, minutes, seconds } = timeDifference
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      setIsZero(true)
    }
    setTimeToNow(getTimeDifference())
  }, 1000)

  return {
    days: timeToNow.days,
    hours: timeToNow.hours,
    minutes: timeToNow.minutes,
    seconds: timeToNow.seconds,
    isZero,
  }
}

export default useCountdown
