import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Heading } from '../Heading/Heading.styled'
import SVGImage from '../DynamicImg/SVGImage'
import device from '../../styles/device'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useCountdown from '../../templates/game/landing/Anticipation/useCountdown'

const CountDownWrapper = styled.div`
  margin: 18px auto 85px;
  @media ${device.tabletPortrait} {
    margin: 20px auto 62px;
  }
  position: relative;
  text-align: center;
  min-width: 230px;
  width: 80%;
  max-width: 577px;
  > div {
    background: ${(props) => props.theme.colors.midAqua};
    position: relative;
    z-index: 1;
  }
  h2 {
    margin: 0 auto;
    width: 80%;
    box-sizing: border-box;
    background: ${(props) => props.theme.colors.midAqua};
    padding: 10px 0 19px;
    display: flex;
    > div {
      padding: 0 10px;
      width: 25%;
      box-sizing: border-box;
    }
  }
  @media ${device.tabletPortrait} {
    h2 {
      width: 360px;
    }
  }
  svg {
    position: absolute;
    z-index: 0;
  }
  svg.mobileIcon {
    right: 25px;
    bottom: -60px;
    @media ${device.tabletPortrait} {
      display: none;
    }
  }
  svg.desktopIcon {
    display: none;
    right: -105px;
    bottom: -10px;
    @media ${device.tabletPortrait} {
      display: block;
    }
  }
`

const Element = styled.div`
  display: flex;
  flex-flow: column;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  color: ${(props) => props.theme.colors.body};
  font-size: ${(props) => props.theme.fonts.ml};
  .unit {
    color: ${(props) => props.theme.colors.darkGreyAlt};
    font-size: ${(props) => props.theme.fonts.mxs};
    font-family: ${(props) => props.theme.fonts.primaryFont};
  }
  @media ${device.tabletPortrait} {
    font-size: ${(props) => props.theme.fonts.xl};
    .unit {
      font-size: ${(props) => props.theme.fonts.s};
    }
  }
`

const CountdownHeading = styled(Heading)`
  font-size: 18px;

  @media ${device.mobPortrait} {
    font-size: 18px;
    font-family: ${theme('fonts.primaryFont')};
    margin-bottom: 0;
    line-height: 1rem;
  }
  @media ${device.tabletPortrait} {
    margin-top: 10px;
    font-size: 22px;
  }
`

interface CountDownElementProps {
  [key: string]: string | number | JSX.Element
}

const CountDownElement: FC<CountDownElementProps> = ({ value, unit }) => (
  <Element>
    <span>{value}</span>
    <span className="unit">{unit}</span>
  </Element>
)

interface CountdownProps {
  title?: string | JSX.Element
}

const Countdown: FC<CountdownProps> = ({ title }) => {
  const { t } = useTranslation()
  const { days, hours, minutes, seconds } = useCountdown()

  return (
    <>
      {title && <CountdownHeading as="h2">{title}</CountdownHeading>}
      <CountDownWrapper>
        <div>
          <h2>
            <CountDownElement value={days} unit={t('components.countdown.days') || ''} />
            <CountDownElement value={hours} unit={t('components.countdown.hours') || ''} />
            <CountDownElement value={minutes} unit={t('components.countdown.minutes') || ''} />
            <CountDownElement value={seconds} unit={t('components.countdown.seconds') || ''} />
          </h2>
        </div>
        <SVGImage
          className="mobileIcon"
          alt={t('aria.alt.birdIcon') as string}
          filename="yellow-bird.svg"
          svgProperties={{ width: '108px', height: '84px' }}
        />
        <SVGImage
          className="desktopIcon"
          alt={t('aria.alt.birdIcon') as string}
          filename="yellow-bird-desktop"
          svgProperties={{ width: '108px', height: '84px' }}
        />
      </CountDownWrapper>
    </>
  )
}

export default Countdown
