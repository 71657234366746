import React, { FC, useEffect } from 'react'
import SEO from '../../../components/seo'
import useGame from '../../../contexts/GameContext/useGame'
import {
  ConfettiMask,
  HeadingWrap,
  InlineHeading,
  LeaderboardLink,
  LeaderboardWrap,
  MainContainer,
} from './dashboard.styled'
import SVGImage from '../../../components/DynamicImg/SVGImage'
import Loading from '../../../components/Loading'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { Heading } from '../../../components/Heading/Heading.styled'
import InlineLeaderboards from '../../../containers/Leaderboards/InlineLeaderboards'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import { graphql } from 'gatsby'
import Awards from './components/Awards'
import CTAGrid from '../../../components/CTAGrid'
import { BeatBoxMapCTA } from '../../../components/CallToAction'
import Surveys from './components/Surveys'
import GameStats from '../../../containers/GameStats'
import Countdown from '../../../components/Countdown/Countdown'
import { useSurvey } from '../../../hooks/useSurvey/useSurvey'
import Wrapper from '../../../components/Wrapper/Wrapper'
import useNavigate from '../../../hooks/useNavigate'
import UpdateMessage from '../../../components/UpdateMessage'
import CompleteGameSetup from '../../../components/CompleteGameSetup'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import { GameStatus } from '../../../generated/api'
import FamilyMemberSelect from '../../../components/FamilymemberSelect'
import SurveyBanner from '../../../components/SurveyBaner'
import DashboardWeeklySection, { WeeklyThemeProps } from './components/DashboardWeeklySection'
import CallToActionPanel from '../../../components/CallToAction/CallToActionPanel'
import useCountdown from '../landing/Anticipation/useCountdown'

const Dashboard: FC<IPageProps<Queries.DashboardQuery>> = ({ data }) => {
  const sanityGame = data?.sanityGame
  const awards = data?.allSanityAwards?.edges
  const { game } = useGame()
  const { user, isAuthenticated } = useAuth()
  const isSustain = game?.info.status === GameStatus.Sustain
  const isFinished = isSustain || game?.info.status === GameStatus.Finished
  const gameWeekNumber = differenceInCalendarWeeks(new Date(), new Date(game?.info.startsAt || ''))
  const showLeaderboard = (!isSustain && gameWeekNumber >= 1) || game?.content?.endOfGameShowWinners
  const { closingSurveyEnabled, tellUsAboutYouSurveyEnabled } = useSurvey()
  const navigate = useNavigate()
  const { isZero } = useCountdown()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
      return
    }
    if (user?.info.gameId !== game?.id) {
      navigate('/')
      return
    }
  }, [user, game?.id])

  if (!game || !user) {
    return <Loading />
  }

  return (
    <>
      <MainContainer>
        <SEO
          title={
            game?.info.name
              ? t('game.dashboard.seoTitle', { gameName: game?.info.name })
              : t('game.dashboard.seoTitleNoGameName')
          }
        />
        {isFinished && (
          <ConfettiMask>
            <SVGImage
              filename="confetti"
              alt={t('aria.alt.confetti') || ''}
              svgProperties={{
                width: 329,
                height: 249,
              }}
            />
          </ConfettiMask>
        )}
        <Wrapper width={'400px'}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
            <FamilyMemberSelect />
          </div>
        </Wrapper>
        {game?.content?.showMessage ? <UpdateMessage /> : null}
        {(closingSurveyEnabled || tellUsAboutYouSurveyEnabled) && game?.info.status !== GameStatus.Finished && (
          <SurveyBanner />
        )}
        {[GameStatus.Live, GameStatus.Anticipation].includes(game?.info.status) && <CompleteGameSetup />}
        <HeadingWrap>
          <InlineHeading>
            <Trans
              i18nKey={isFinished ? 'game.dashboard.titleFinished' : 'game.dashboard.titleLive'}
              values={{
                name: user?.profile.firstName ? ` ${user?.profile.firstName}` : '',
              }}
              components={{ span: <span /> }}
            />
          </InlineHeading>
        </HeadingWrap>
        {!game?.content?.hideLeaderboards ? (
          <>
            {game && <GameStats onWhite carousel carouselColumns={2} />}
            {game?.info.startsAt && new Date(game?.info.startsAt) > new Date() && !isZero && (
              <Countdown title={t('game.dashboard.countdownTitle') || ''} />
            )}
            {(closingSurveyEnabled || tellUsAboutYouSurveyEnabled) && game?.info.status !== GameStatus.Finished ? (
              <Surveys />
            ) : null}
            <Heading>
              {isSustain ? t('game.dashboard.leaderboards.titleFinished') : t('game.dashboard.leaderboards.titleLive')}
            </Heading>
            {showLeaderboard && (
              <LeaderboardWrap>
                <InlineLeaderboards />
                <LeaderboardLink to="/leaderboards" $primary replace>
                  {t('game.dashboard.leaderboards.buttonText') || ''}
                </LeaderboardLink>
              </LeaderboardWrap>
            )}
          </>
        ) : game?.content?.showMessage ? (
          <UpdateMessage />
        ) : null}
        {sanityGame && (
          <>
            <DashboardWeeklySection
              weeklyThemes={sanityGame?.weeklyThemes as unknown as WeeklyThemeProps[]}
              currentWeek={gameWeekNumber}
              game={game}
            >
              <CTAGrid>
                <BeatBoxMapCTA context={{ game }} />
                {sanityGame?.ctaListForLoggedInUsers?.map((v) => {
                  if (!v) return null
                  return <CallToActionPanel key={v.id} {...v} context={{ game }} />
                })}
              </CTAGrid>
            </DashboardWeeklySection>
            {sanityGame?.awards && <Awards awards={awards} />}
          </>
        )}
      </MainContainer>
    </>
  )
}

export default Dashboard

export const query = graphql`
  query Dashboard($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      awards
      ...gameDetails
      ...weeklyThemes
      ctaListForLoggedInUsers {
        ...cta
      }
    }
    allSanityAwards(filter: { i18n_lang: { eq: $language } }) {
      edges {
        node {
          name
          description
          range
          type
          motivator
          icon {
            asset {
              url
            }
          }
          iconBackground {
            rgb {
              r
              g
              b
              a
            }
          }
        }
      }
    }
  }
`
